import { CartItem as CartItemType, StoreInterface, WhatsappCheckoutOption } from "../../../assets/interfaces";
import symbols from "../../../assets/js/utils/currency-symbols";
import { genarateStringFromVariantValues, shuffleArray } from "../../../assets/js/utils/functions";
import { resolvePhone, sendFacebookEvent } from "../../../assets/js/utils/utils";
import CartContext from "../../../contexts/cart-context";
import { AppBtn } from "../../ui/buttons";
import ErrorLabel from "../../ui/error-label";
import { ModalBody, ModalFooter } from "../../ui/modal";
import CartItem from "./cart-item";
import { useRequest } from "@/api/utils";
import { CompleteSelectionParams } from "@/api/interfaces/whatsapp.interface";
import { CompleteSelection } from "@/api/whatsapp";

interface Props {
  store: StoreInterface;
  nextStep: VoidFunction;
}

export const CartStep: React.FC<Props> = ({ store, nextStep }) => {
  const {
    cart,
    addOrRemoveItem,
    updateItemQuantity,
    setQuantity,
    price,
    cartLink,
    cartError,
    setCartError,
    removeItem,
    formatAsCurrency,
    currencies,
  } = CartContext.useContainer();

  const checkoutOptions = shuffleArray<WhatsappCheckoutOption>(store?.checkout_channels?.whatsapp);

  const getEnquiryLink = (prefix: string, phone: string) => {
    return encodeURI(
      `https://api.whatsapp.com/send/?phone=${resolvePhone(phone)}&text=${prefix}\n\n${cart
        .map((c) => (c.is_deleted || c.is_unavailable ? "" : `📦 ${getFullProductName(c)} - ${c.quantity}x\n`))
        .join("")}\n\n💰 Total price: *${price}*\n\nCheck out cart here:\n${cartLink}`
    );
  };

  const proceedToBuy = () => {
    if (cart.some((c) => c.is_deleted || c.is_unavailable)) {
      setCartError("Please remove unavailable items from cart");
      return;
    }

    sendFacebookEvent("InitiateCheckout", {
      content_ids: cart.map((c) => c.item.id),
      num_items: cart.length,
      value: price,
      currency: currencies.active,
      contents: cart.map((c) => ({ id: c.item.id, quantity: c.quantity, item_price: c.item.price })),
    });
    nextStep();
  };

  const enquire = () => {
    window.open(
      getEnquiryLink("Hi, i'll like to make an enquiry about these items:", resolvePhone(checkoutOptions[0].phone))
    );
    return;
  };

  return (
    <>
      <ModalBody>
        <div className="grid grid-cols-1 sm:grid-cols-[repeat(auto-fit,minmax(280px,1fr))] gap-2.5 h-full overflow-y-auto">
          <div className="-mb-5">
            <ErrorLabel error={cartError} perm={true} />
          </div>
          {/* <div> */}
          {cart.map((item, index) => (
            <CartItem
              key={index}
              {...{
                item,
                updateItemQuantity,
                addOrRemoveItem,
                setItemQuantity: setQuantity,
                removeItem,
                formatAsCurrency,
              }}
            />
          ))}
          {/* </div> */}
          {cart.length > 0 && (
            <div className="flex items-center justify-between text-sm py-2.5 px-3 bg-grey-fields-100 rounded-8">
              <div className="flex items-center gap-1.25 text-black-placeholder">
                <figure className="h-6 w-6 rounded-full bg-accent-yellow-500 text-white flex items-center justify-center">
                  <span className="font-medium text-sm">{symbols[currencies.active]}</span>
                </figure>

                <span className="text-black-muted text-1xs sm:text-sm font-medium">Total price:</span>
              </div>
              <span className="text-primary-500 text-1xs sm:text-sm font-semibold">{price}</span>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="w-full">
          <div className="flex items-center gap-x-3">
            <AppBtn className="flex-1" color="neutral" onClick={() => enquire()} size="lg">
              Make an enquiry
            </AppBtn>
            <AppBtn className="flex-1" onClick={proceedToBuy} disabled={cart.length < 1} size="lg">
              Proceed
            </AppBtn>
          </div>
        </div>
      </ModalFooter>
    </>
  );
};

export default CartStep;

export const getFullProductName = (c: CartItemType) => {
  let name = "";

  if (c?.variant) {
    if (c?.item?.variants.type === "custom") {
      name = `${c.item.name} (${genarateStringFromVariantValues(c?.variant?.values)})`;
    } else {
      const hasExtraOption = c.item?.variants?.options[0]?.values !== undefined;
      name = `${c.item.name} (Option ${c.item.variants.options.findIndex((o) => o.id === c.variant.id) + 1}${
        hasExtraOption ? ` - ${Object.values(c?.variant?.values)[0]}` : ""
      })`;
    }
  } else {
    name = c.item.name;
  }
  return name.replace(/&/g, "and");
};
