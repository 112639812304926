import { CartItem as CartItemType, ProductItemInterface } from "../../../assets/interfaces";
import { capitalizeFirstLetter, genarateStringFromVariantValues, toCurrency } from "../../../assets/js/utils/functions";
import { checkItemAvailability } from "../../../assets/js/utils/utils";
import { FormatCurrencyFun } from "../../../contexts/cart-context";

interface CartItemProps {
  item: CartItemType;
  updateItemQuantity: (id: string, dir: "+" | "-", variant?: string) => void;
  addOrRemoveItem: (item: ProductItemInterface, variants?: string[]) => void;
  setItemQuantity: (id: string, quantity: number, variant_id?: string) => void;
  removeItem: (id: string, variant_id?: string) => void;
  formatAsCurrency: FormatCurrencyFun;
}

const CartItem: React.FC<CartItemProps> = ({
  item,
  updateItemQuantity,
  setItemQuantity,
  removeItem,
  formatAsCurrency,
}) => {
  const isImageVariant = item.item?.variants?.type === "images" && item.variant;
  const isCustomVariant = item.item?.variants?.type === "custom" && item.variant;
  const deletedOrUnavailable =
    item.is_deleted || item.is_unavailable || !checkItemAvailability(item.item, item?.variant);
  const variantHasExtraOption = isImageVariant && item.item?.variants?.options[0]?.values !== undefined;

  const getItemPrice = () =>
    formatAsCurrency(
      (item.variant ? item.variant.discount_price ?? item.variant.price : item.item.discount_price ?? item.item.price) *
        item.quantity
    );

  return (
    <article className="border-b border-grey-border border-opacity-50 last:border-b-0 w-full py-4 flex items-stretch cursor-pointer overflow-hidden">
      <figure
        className={`h-[55px] w-[55px] sm:h-15 sm:w-15 rounded-10 overflow-hidden flex-shrink-0 ${
          deletedOrUnavailable ? "opacity-50" : ""
        }`}
      >
        <img
          src={
            isImageVariant && !deletedOrUnavailable ? item?.variant?.image : item?.item?.images[item.item?.thumbnail]
          }
          alt=""
          className="h-full w-full object-cover"
        />
      </figure>
      <div className="ml-3.75 flex flex-col justify-between flex-1 overflow-hidden">
        <h5
          className={`text-black-secondary font-display font-bold overflow-hidden mb-1.5 text-sm sm:text-1sm max-w-full whitespace-nowrap overflow-ellipsis ${
            deletedOrUnavailable ? "opacity-50" : ""
          }`}
        >
          {capitalizeFirstLetter(item?.item?.name)}{" "}
          {isCustomVariant && !item.is_deleted && (
            <span className="text-accent-orange-500 inline-flex items-center px-2 py-0.5 bg-grey-fields-100 text-xxs rounded-30 font-body font-semibold ml-1">
              {genarateStringFromVariantValues(item?.variant?.values)}
            </span>
          )}
          {variantHasExtraOption && !item.is_deleted && (
            <span className="text-accent-orange-500 inline-flex items-center px-2 py-0.5 bg-grey-fields-100 text-xxs rounded-30 font-body font-semibold capitalize ml-1">
              {Object.keys(item.item?.variants?.options[0]?.values)[0]} {Object.values(item?.variant?.values)[0]}
            </span>
          )}
        </h5>
        <div className="flex w-full items-center justify-between">
          {deletedOrUnavailable ? (
            <span className="bg-accent-red-500 px-1.5 text-white py-0.75 text-xxs inline-flex rounded-5 font-semibold">
              {item.is_deleted ? "Deleted by merchant" : "Out of stock"}
            </span>
          ) : (
            <div className="flex items-center space-x-2.5">
              <button
                className="flex-shrink-0 h-7 w-7 sm:h-8 sm:w-8 rounded-full bg-grey-fields-100 border border-grey-border border-opacity-50 inline-flex items-center justify-center text-placeholder shadow-primary disabled:cursor-not-allowed"
                disabled={item.quantity < 2}
                onClick={() => updateItemQuantity(item.item_id, "-", item?.variant?.id)}
              >
                {/* prettier-ignore */}
                <svg className="w-[60%]" viewBox="0 0 18 18" fill="none">
                  <path d="M4.5 9H13.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>

              <div className="flex items-stretch">
                <input
                  type="number"
                  className=" w-8 block h-7.5 sm:w-8 sm:h-8 rounded-l-5 border-grey-divider focus:border-primary-500 border no-outline text-center  font-semibold text-xxxs sm:text-xs text-dark"
                  value={item.quantity}
                  onChange={(e) => setItemQuantity(item.id, Number(e.target.value), item?.variant?.id)}
                  min={1}
                  max={10}
                />
                <span className=" p-2 inline-flex items-center border border-l-0 border-grey-divider rounded-r-5 text-dark font-semibold text-xxs sm:text-xs h-7.5 sm:h-8 px-2">
                  {getItemPrice()}
                </span>
              </div>
              <button
                onClick={() => updateItemQuantity(item.item_id, "+", item?.variant?.id)}
                className="flex-shrink-0 h-7 w-7 sm:h-8 sm:w-8 rounded-full bg-grey-fields-100 border  border-grey-border border-opacity-50 inline-flex items-center justify-center text-placeholder shadow-primary"
              >
                {/* prettier-ignore */}
                <svg className="w-[60%]" viewBox="0 0 18 18" fill="none">
                  <path d="M4.5 9H13.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 13.5V4.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          )}

          <button
            className="flex-shrink-0 ml-1 h-7.5 w-7.5 sm:h-8 sm:w-8 rounded-full text-accent-red-500 bg-grey-fields-100 flex items-center justify-center shadow-accent border border-grey-border border-opacity-40"
            onClick={() => removeItem(item.item_id, item?.variant_id ? item?.variant_id : undefined)}
          >
            {/* prettier-ignore */}
            <svg className="w-[50%]" viewBox="0 0 18 18" fill="none">
              <path d="M15.75 4.48499C13.2525 4.23749 10.74 4.10999 8.235 4.10999C6.75 4.10999 5.265 4.18499 3.78 4.33499L2.25 4.48499" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="1.5"/>
              <path d="M6.375 3.7275L6.54 2.745C6.66 2.0325 6.75 1.5 8.0175 1.5H9.9825C11.25 1.5 11.3475 2.0625 11.46 2.7525L11.625 3.7275" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="1.5"/>
              <path d="M14.1373 6.85498L13.6498 14.4075C13.5673 15.585 13.4998 16.5 11.4073 16.5H6.5923C4.4998 16.5 4.4323 15.585 4.3498 14.4075L3.8623 6.85498" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="1.5"/>
              <path d="M7.74707 12.375H10.2446" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="1.5"/>
              <path d="M7.125 9.375H10.875" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="1.5"/>
            </svg>
          </button>
        </div>
      </div>
    </article>
  );
};

export default CartItem;
