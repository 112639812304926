import classNames from "classnames";
import { FormikProps } from "formik";
import React, { Children, ReactElement, forwardRef, useEffect, useRef, useState } from "react";
import cx from "classnames";
import useClickOutside from "@/components/hooks/useClickOutside";
import useDebounce from "@/components/hooks/useDebounce";

export interface InputOptions {
  text: string;
  value: any;
  alt?: string;
}
export interface InputFieldProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string;
  label: string;
  name: string;
  error?: string;
  prefixIcon?: ReactElement;
  isLoading?: boolean;
  generateOptions: (value: string) => Promise<InputOptions[]>;
  debounceDelay?: number;
}
const InputFieldWithAutocomplete: React.FC<InputFieldProps> = (_props) => {
  const {
    label,
    disabled,
    className,
    name,
    children,
    error,
    prefixIcon,
    isLoading,
    debounceDelay,
    onChange,
    generateOptions,
    value,
    ...props
  } = _props;

  const [inputCache, setInputCache] = useState("");
  const [options, setOptions] = useState<InputOptions[]>([]);
  const containerRef = useRef(null);
  const computedValue = value || inputCache;

  useDebounce(inputCache, 200, handleInputChange);
  useClickOutside(containerRef, () => {
    setOptions([]);
  });

  async function handleInputChange() {
    if (inputCache.trim() !== "") {
      onChange({ target: { name, value: "", id: props.id } } as any);
      const options = await generateOptions(inputCache);
      setOptions(options);
    } else setOptions([]);
  }

  const handleSelect = (value: string) => {
    onChange({ target: { name, value, id: props.id } } as any);
    setOptions([]);
  };

  return (
    <div ref={containerRef} className={`w-full relative mt-4 first:mt-0 ${className} ${disabled && "opacity-60"}`}>
      <div className={`h-12.5 w-full relative flex items-center font-action`}>
        <input
          {...props}
          onChange={(e) => setInputCache(e?.target?.value)}
          name={name}
          disabled={disabled}
          value={computedValue}
          placeholder=" "
          className={classNames(
            `input-field h-full w-full !outline-none border border-grey-divider bg-grey-fields-100 bg-opacity-40 rounded-lg text-base placeholder-shown:text-1sm text-black-secondary px-4 sm:px-5`,
            { "!pl-[34px]": prefixIcon }
          )}
        />
        {prefixIcon && <div className="pl-2.5 absolute">{prefixIcon}</div>}
        <label
          htmlFor={name}
          className={classNames(`text-sm text-placeholder absolute cursor-text pointer-events-none`, {
            "has-prefix ml-[34px]": prefixIcon,
          })}
        >
          {/* {prefixIcon && <div className="pr-1.5">{prefixIcon}</div>} */}
          {label}
        </label>
        {children}
      </div>
      {options.length > 0 && (
        <div
          className={classNames(
            `absolute top-full bg-white shadow-card rounded-10 border border-grey-header z-[200] w-full transform transition-all duration-100 ease-in-out overflow-hidden`,
            { "-translate-y-4": error } //dropdown should overlay error label if error value is set
          )}
        >
          <ul className="flex flex-col divide-y divide-grey-divider max-h-[220px] overflow-y-auto">
            {options.map(({ value, text, alt }, index) => (
              <li
                className="py-3 px-5 flex items-center text-sm cursor-pointer hover:bg-grey-light text-dark"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelect(value);
                }}
                key={index}
              >
                <span className="inline-flex">{alt ?? text}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
      {error && (
        <div className="text-accent-red-500 text-xs font-medium mt-1 flex items-center">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="mr-1">
            <path
              d="M7.018 3.55288L2.15392 11.6731C2.05363 11.8467 2.00057 12.0437 2 12.2442C1.99944 12.4447 2.0514 12.6419 2.15071 12.8162C2.25003 12.9904 2.39323 13.1356 2.56607 13.2373C2.73892 13.339 2.93538 13.3937 3.13592 13.3959H12.8641C13.0646 13.3937 13.2611 13.339 13.4339 13.2373C13.6068 13.1356 13.75 12.9904 13.8493 12.8162C13.9486 12.6419 14.0006 12.4447 14 12.2442C13.9994 12.0437 13.9464 11.8467 13.8461 11.6731L8.982 3.55288C8.87963 3.3841 8.73548 3.24456 8.56347 3.14772C8.39146 3.05088 8.1974 3 8 3C7.8026 3 7.60854 3.05088 7.43653 3.14772C7.26452 3.24456 7.12037 3.3841 7.018 3.55288V3.55288Z"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M8 6.50452V8.8016" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 11.0992H8.00718" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="inline-block">Please select an address from the dropdown</span>
        </div>
      )}
    </div>
  );
};

export default InputFieldWithAutocomplete;
