import { getFieldvalues } from "@/assets/js/utils/functions";
import InputFieldWithAutocomplete, { InputOptions } from "@/components/ui/form-elements/input-field-with-autocomplete";
import { FormikProps } from "formik";
import React from "react";

interface IProps {
  form: FormikProps<any>;
  formKey: string;
}

const AddressAutocompleteInput: React.FC<IProps> = (props) => {
  const { form, formKey } = props;

  function generateAddressOptions(address: string) {
    const sessionToken = new (window as any).google.maps.places.AutocompleteSessionToken();
    const autocompleteService = new (window as any).google.maps.places.AutocompleteService();
    const optionsPromise = new Promise<InputOptions[]>((reslolve, reject) => {
      autocompleteService.getPlacePredictions(
        {
          input: address,
          sessionToken: sessionToken,
          locationBias: "IP_BIAS",
        },
        (data, status) => {
          if (status === "OK") reslolve(data.map(({ description }) => ({ text: description, value: description })));
          else reject("Failed to get place predictions");
        }
      );
    });
    return optionsPromise;
  }

  return (
    <InputFieldWithAutocomplete
      generateOptions={generateAddressOptions}
      label="Address"
      {...getFieldvalues(formKey, form)}
    />
  );
};

export default AddressAutocompleteInput;
